<template>
  <v-dialog
    v-model="isVisible"
    max-width="320"
    :persistent="isSaving"
  >
    <v-card>
      <v-card-title class="primary white--text">Modifier le quota</v-card-title>

      <v-card-text class="pt-5">
        <v-form ref="quotaForm" @submit.prevent="updateCursusQuota">
          <v-text-field
            label="Quota"
            v-model="quota"
            :rules="[rules.quota]"
            autofocus
          ></v-text-field>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="justify-space-between">
        <v-btn
          text
          @click="isVisible = false"
          :disabled="isSaving"
        >
          Annuler
        </v-btn>

        <v-btn
          color="primary"
          text
          @click="updateCursusQuota"
          :loading="isSaving"
        >
          Enregistrer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'updateCursusQuotaDialog',

  props: {
    value: Boolean,
    editedCursus: Object,
  },

  data: () => ({
    isSaving: false,
    quota: '',
    rules: {
      quota: (v) => (/^(0|[1-9][0-9]{0,2})$/.test(v)) || 'Le quota doit être compris entre 0 et 999',
    },
  }),

  computed: {
    isVisible: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit('input', val);
      },
    },
  },

  methods: {
    ...mapMutations({ showNotification: 'SET_NOTIFICATION' }),

    async updateCursusQuota() {
      if (!this.$refs.quotaForm.validate()) return;
      await this.$store.dispatch('getAccessToken');
      let notificationText;
      let notificationColor;
      try {
        this.isSaving = true;
        const response = await this.$http.post('admin/cursus-update-quota.php', {
          cursus_id: this.editedCursus.id,
          quota: this.quota,
        });
        this.$emit('update-quota', this.quota);
        notificationText = response.data;
        notificationColor = 'success';
        this.isVisible = false;
      } catch (error) {
        notificationText = error.response.data;
        notificationColor = 'error';
      } finally {
        this.isSaving = false;
        this.showNotification({
          isVisible: true,
          text: notificationText,
          color: notificationColor,
        });
      }
    },
  },

  watch: {
    isVisible(val) {
      if (!val) this.$refs.quotaForm.reset();
      else this.quota = this.editedCursus.quota;
    },
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" Cursus ")]),_c('v-spacer'),(_vm.$store.getters.hasAdminRole && _vm.instanceStatus !== 'Terminée')?_c('import-cursus-dialog',{attrs:{"importedCursus":_vm.cursus,"instanceId":_vm.instanceId},on:{"import-cursus":_vm.getCursus}}):_vm._e(),(_vm.$store.getters.hasAdminRole && _vm.instanceStatus === 'En attente')?_c('delete-cursus-dialog',{attrs:{"cursus":_vm.selectedCursus,"instanceId":_vm.instanceId},on:{"delete-cursus":_vm.removeCursus}}):_vm._e(),(_vm.$store.getters.hasAdminRole && _vm.instanceStatus !== 'Terminée')?_c('save-cursus-dialog',{attrs:{"instanceId":_vm.instanceId,"instanceType":_vm.instanceType},on:{"save-cursus":_vm.insertCursus}}):_vm._e(),(_vm.$store.getters.hasAdminRole && _vm.instanceStatus !== 'Terminée')?_c('update-cursus-quota-dialog',{attrs:{"editedCursus":_vm.editedCursus},on:{"update-quota":_vm.updateQuota},model:{value:(_vm.updateCursusQuotaDialog),callback:function ($$v) {_vm.updateCursusQuotaDialog=$$v},expression:"updateCursusQuotaDialog"}}):_vm._e()],1),_c('v-data-table',{attrs:{"headers":_vm.dynamicHeaders,"items":_vm.cursus,"sort-by":"name","group-by":"dept_acronym","disable-pagination":"","hide-default-footer":"","show-select":_vm.$store.getters.hasAdminRole && _vm.instanceStatus === 'En attente',"loading":_vm.isLoadingCursus},on:{"click:row":_vm.goTo},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var headers = ref.headers;
var group = ref.group;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('td',{staticClass:"dept-header",attrs:{"colspan":headers.length},on:{"click":toggle}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{attrs:{"icon":""}},[(!isOpen)?_c('v-icon',[_vm._v("mdi-chevron-down")]):_c('v-icon',[_vm._v("mdi-chevron-up")])],1),_c('v-icon',{staticClass:"mx-6"},[_vm._v(_vm._s(_vm.getDeptIcon(group)))]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(group))])],1)])]}},{key:"item.est_name",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","label":""}},[_vm._v(_vm._s(item.est_name.toUpperCase()))])]}},{key:"item.quota",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"outlined":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.editQuota(item)}}},[_vm._v(" "+_vm._s(item.quota)+" ")])]}}]),model:{value:(_vm.selectedCursus),callback:function ($$v) {_vm.selectedCursus=$$v},expression:"selectedCursus"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-card outlined>
    <v-toolbar flat>
      <v-toolbar-title>
        Cursus
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <import-cursus-dialog
        @import-cursus="getCursus"
        :importedCursus="cursus"
        :instanceId="instanceId"
        v-if="$store.getters.hasAdminRole && instanceStatus !== 'Terminée'"
      ></import-cursus-dialog>

      <delete-cursus-dialog
        @delete-cursus="removeCursus"
        :cursus="selectedCursus"
        :instanceId="instanceId"
        v-if="$store.getters.hasAdminRole && instanceStatus === 'En attente'"
      ></delete-cursus-dialog>

      <save-cursus-dialog
        @save-cursus="insertCursus"
        :instanceId="instanceId"
        v-if="$store.getters.hasAdminRole && instanceStatus !== 'Terminée'"
        :instanceType="instanceType"
      ></save-cursus-dialog>

      <update-cursus-quota-dialog
        v-model="updateCursusQuotaDialog"
        :editedCursus="editedCursus"
        @update-quota="updateQuota"
        v-if="$store.getters.hasAdminRole && instanceStatus !== 'Terminée'"
      ></update-cursus-quota-dialog>
    </v-toolbar>

    <v-data-table
      v-model="selectedCursus"
      :headers="dynamicHeaders"
      :items="cursus"
      sort-by="name"
      group-by="dept_acronym"
      disable-pagination
      hide-default-footer
      :show-select="$store.getters.hasAdminRole && instanceStatus === 'En attente'"
      @click:row="goTo"
      :loading="isLoadingCursus"
    >
      <template v-slot:group.header="{ headers, group, isOpen, toggle }">
        <td
          :colspan="headers.length"
          class="dept-header"
          @click="toggle"
        >
          <div class="d-flex align-center">
            <v-btn icon>
              <v-icon v-if="!isOpen">mdi-chevron-down</v-icon>
              <v-icon v-else>mdi-chevron-up</v-icon>
            </v-btn>

            <v-icon class="mx-6">{{ getDeptIcon(group) }}</v-icon>

            <span class="font-weight-bold">{{ group }}</span>
          </div>
        </td>
      </template>

      <template v-slot:item.est_name="{ item }">
        <v-chip small label>{{ item.est_name.toUpperCase() }}</v-chip>
      </template>

      <template v-slot:item.quota="{ item }">
        <v-btn
          @click.stop="editQuota(item)"
          outlined
          small
        >
          {{ item.quota }}
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapMutations } from 'vuex';
import { getDeptIcon } from '@/mixins/methodsMixin';
import ImportCursusDialog from '@/components/cursus/ImportCursusDialog.vue';
import DeleteCursusDialog from '@/components/cursus/DeleteCursusDialog.vue';
import SaveCursusDialog from '@/components/cursus/SaveCursusDialog.vue';
import updateCursusQuotaDialog from '@/components/cursus/updateCursusQuotaDialog.vue';

export default {
  name: 'AdminDahuCursus',

  components: {
    'import-cursus-dialog': ImportCursusDialog,
    'delete-cursus-dialog': DeleteCursusDialog,
    'save-cursus-dialog': SaveCursusDialog,
    'update-cursus-quota-dialog': updateCursusQuotaDialog,
  },

  mixins: [getDeptIcon],

  props: {
    instanceId: String,
    instanceStatus: String,
    instanceType: String,
  },

  data: () => ({
    headers: [
      { text: 'NOM', value: 'name' },
      { text: 'IMPLANTATION', value: 'est_name' },
      // { text: 'GESTAC', value: 'id_gestac' },
      { text: 'QUOTA', value: 'quota' },
    ],
    cursus: [],
    selectedCursus: [],
    rules: {
      quota: (v) => (v && /^(0|[1-9][0-9]{0,2})$/.test(v)) || 'Le quota doit être compris entre 0 et 999',
    },
    updateCursusQuotaDialog: false,
    editedCursus: null,
    isLoadingCursus: false,
  }),

  created() {
    this.getCursus();
  },

  computed: {
    dynamicHeaders() {
      if (this.instanceStatus === 'En attente') return this.headers;
      return [...this.headers, { text: 'INSCRIPTIONS', value: 'registration_number', align: 'right' }];
    },
  },

  methods: {
    ...mapMutations({ showNotification: 'SET_NOTIFICATION' }),

    insertCursus(cursus) {
      this.cursus.push(cursus);
    },

    removeCursus() {
      this.cursus = this.cursus.filter((cursus) => !this.selectedCursus.includes(cursus));
      this.selectedCursus = [];
    },

    editQuota(item) {
      if (!this.$store.getters.hasAdminRole) return;
      this.editedCursus = item;
      this.updateCursusQuotaDialog = true;
    },

    updateQuota(newQuota) {
      this.editedCursus.quota = newQuota;
      this.updateCursusQuotaDialog = false;
    },

    goTo(row) {
      if (row.registration_number === 0) return;
      this.$router.push(`/admin/dahu/${this.instanceId}/cursus/${row.id}`);
    },

    async getCursus() {
      await this.$store.dispatch('getAccessToken');
      try {
        this.isLoadingCursus = true;
        const response = await this.$http.get(`admin/cursus-get.php?instance_id=${this.instanceId}&type=DAHU`);
        this.cursus = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingCursus = false;
      }
    },
  },

  watch: {
    updateCursusQuotaDialog(val) {
      if (!val) {
        this.editedCursus = null;
      }
    },
  },
};
</script>

<style>

.dept-header {
  cursor: pointer;
  background-color: #e6f1fc;
}

</style>
